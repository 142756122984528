// Third Party Plugins
import React from 'react';
import Link from 'next/link';
// API - Local Data
import navLinkData from '@/components/ui/Nav/data/navLinksData';
import socialMediaData from '@/components/ui/Nav/data/socialMediaData';
// Styles
import { StyledFooter } from './styles';
// Images
import AEFLogoLight from '@svg/aef-lightmode-logo.svg';
// Custom Components
import SitemapMapper from '@/components/ui/Nav/_components/SiteMapMapper';

function Footer() {
  return (
    <StyledFooter>
      {/* Render logo */}
      <div className="logo">
        <Link href="/">
          <AEFLogoLight width={51} height={63} />
        </Link>
      </div>
      {/* Render copyright */}
      <div className="copyright">
        <p>copyright &copy; {new Date().getFullYear()}</p>
        <p>animation educators forum</p>
        <p>all rights reserved</p>
      </div>
      {/* Render sitemap */}
      <ul className="sitemap">
        {/* Map over nav link data to render sitemap sections */}
        <SitemapMapper title="About" links={navLinkData.about} />
        <SitemapMapper title="Engage" links={navLinkData.engage} />
        <SitemapMapper title="Programs" links={navLinkData.programs} />
        <SitemapMapper
          title="Connect"
          links={navLinkData.connect}
          social={socialMediaData}
        />
      </ul>
    </StyledFooter>
  );
}

export default Footer;
