'use client';
import styled from 'styled-components';

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
  padding: 10px;
  height: auto;

  svg {
    max-height: 100%;
    height: auto;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 5px; /* Smaller padding for smaller screens */
    svg {
      max-height: 100%; /* Smaller height for mobile screens */
      width: 100%; /* Ensure the width is auto to maintain aspect ratio */
      height: auto; /* Maintain aspect ratio */
    }
  }

  @media screen and (max-width: 480px) {
    padding: 2px; /* Even smaller padding for very small screens */
    svg {
      max-height: 20px; /* Smaller height for very small screens */
      width: 100%; /* Ensure the width is auto to maintain aspect ratio */
      height: auto; /* Maintain aspect ratio */
    }
  }
`;

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--c-grey-1);
  padding: var(--size-half) 0;

  @media screen and (max-width: 768px) {
    padding: var(--size-dual-nudge) 0;
    height: auto;
  }

  li {
    list-style: none;
  }

  .desktop-nav {
    display: flex;
    margin-left: auto; /* Ensures the navbar items are pushed to the right */
  }

  .mobile-nav {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .desktop-nav {
      display: none;
    }

    .mobile-nav {
      display: flex;
    }
  }
`;
