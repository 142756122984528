// Third Party Plugins
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
// Hooks
import useActiveLink from '@hooks/useActiveLink';
// Types
import { LinkComponentProps } from './types'; // Adjust the import path if needed
// Styles
import { StyledLinks } from './styles';

const LinkComponent: React.FC<LinkComponentProps> = ({ item }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const isActive = useActiveLink(item?.url || '');

  if (!isClient) {
    return null; // or a loading indicator
  }

  if (!item || !item.url) {
    return <span>Invalid item</span>;
  }

  return (
    <StyledLinks>
      {item.type === 'nextjs' ? (
        <Link href={item.url} passHref legacyBehavior>
          <a className={isActive ? 'active' : ''}>{item.text}</a>
        </Link>
      ) : (
        <a href={item.url} target="_blank" rel="noopener noreferrer">
          {item.text}
        </a>
      )}
    </StyledLinks>
  );
};

export default LinkComponent;
