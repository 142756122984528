'use client';
import styled from 'styled-components';

type StyledLayoutContainerProps = {
  $gap: boolean;
  customGap?: string;
};
export const StyledLayoutContainer = styled.div<StyledLayoutContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 var(--size-1);
  margin: 0 auto;
  gap: ${(props) => (props.$gap ? props.customGap || `var(--size-7)` : 0)};

  @media screen and (max-width: 768px) {
    margin: 0; /* Remove center margin on phones */
    padding: 0 var(--size-half); /* Optionally adjust padding */
  }
`;
