import { useEffect, useState } from 'react';

const useActiveLink = (url: string): boolean => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentPath = window.location.pathname;
      setIsActive(currentPath === url);
    }
  }, [url]);

  return isActive;
};

export default useActiveLink;
