// external libraries
import {
  faFacebook,
  faTwitter,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
// types
import { SocialMediaDataProps } from './types';

const socialMediaData: SocialMediaDataProps[] = [
  {
    id: 'facebook',
    platform: 'Facebook',
    url: 'https://www.facebook.com/Animation-Educators-Forum-205710355776/',
    icon: faFacebook,
  },
  {
    id: 'twitter',
    platform: 'Twitter',
    url: 'https://twitter.com/aeforum',
    icon: faTwitter,
  },
  {
    id: 'youtube',
    platform: 'YouTube',
    url: 'https://www.youtube.com/channel/UCfXREW1PdflKAjup9hXKiNw',
    icon: faYoutube,
  },
  {
    id: 'instagram',
    platform: 'Instagram',
    url: 'https://www.instagram.com/animationeducatorsforum/',
    icon: faInstagram,
  },
];

export default socialMediaData;
