'use client';

// Third Party Plugins
import React from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Types
import { MobileNavProps } from './types';
import { NavLinkDataProps } from '../../data/navLinksData/types';
// Styles
import {
  StyledMenuButtonContainer,
  StyledToggleButton,
  StyledMenuSidebar,
  StyledMenuContainer,
  StyledLinkHeading,
  StyledLinkContainer,
  StyledSocialContainer,
  StyledSocialLink,
  StyledImageWrapper,
} from './styles';
// Images
import AsifaLogo from '@images/asifa.png';
// Custom Components
import VisuallyHidden from '@/components/ui/VisuallyHidden';
import LinkMapper from '../LinkComponent/LinkMapper';

const MobileNav: React.FC<MobileNavProps> = ({
  navLinkData,
  socialMediaData,
  menuOpen,
  setMenuOpen,
}) => (
  <>
    <StyledMenuButtonContainer className="mobileNav">
      <StyledToggleButton type="button" onClick={() => setMenuOpen(!menuOpen)}>
        <VisuallyHidden>
          {menuOpen ? `Close nav menu` : `Open nav menu`}
        </VisuallyHidden>
        <div className={menuOpen ? `open` : `close`} />
      </StyledToggleButton>
    </StyledMenuButtonContainer>
    <StyledMenuSidebar $open={menuOpen}>
      <StyledMenuContainer>
        {['About', 'Engage', 'Programs'].map((section) => (
          <React.Fragment key={section}>
            <StyledLinkHeading>{section}</StyledLinkHeading>
            <StyledLinkContainer>
              <LinkMapper
                data={
                  navLinkData[section.toLowerCase() as keyof NavLinkDataProps]
                }
              />
            </StyledLinkContainer>
          </React.Fragment>
        ))}
        <StyledLinkHeading>Connect</StyledLinkHeading>
        {navLinkData.connect.map((item, index) => (
          <StyledLinkContainer key={index}>
            <LinkMapper data={item} />
          </StyledLinkContainer>
        ))}
        <StyledSocialContainer>
          <div>
            <a
              href="https://www.asifa-hollywood.org/"
              target="_blank"
              rel="norefer noopener noreferrer"
            >
              <StyledImageWrapper>
                <Image
                  src={AsifaLogo}
                  alt="ASIFA-Hollywood Logo"
                  fill
                  style={{ objectFit: 'contain' }}
                  sizes="(max-width: 600px) 100vw, 50vw"
                />
              </StyledImageWrapper>
            </a>
          </div>
          {socialMediaData.map((i) => (
            <StyledSocialLink
              key={i.platform}
              href={i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={i.icon} />
            </StyledSocialLink>
          ))}
        </StyledSocialContainer>
      </StyledMenuContainer>
    </StyledMenuSidebar>
  </>
);

export default MobileNav;
