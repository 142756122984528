// Third Party Plugins
import React, { useState } from 'react';
import { faShareAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Images
import AsifaLogo from '@images/asifa.png';
// Styles
import {
  StyledLogo,
  NavContainer,
  LinkList,
  SocialContainer,
  ToggleButton,
  ToggleContainer,
} from './styles';
// Types
import { DesktopNavProps } from './types';
// Custom Components
import NavLinks from '../NavLinks';
import SocialLinks from '../SocialLinks';
import { mapSocialMediaData } from './utilities';

// DesktopNav Component
// This component renders the navigation bar for the desktop view,
// including navigation links, action links, social media links, and a toggle button for social media visibility.
const DesktopNav: React.FC<DesktopNavProps> = ({
  location,
  navLinkData,
  socialMediaData,
}) => {
  // State to control the visibility of social media links
  const [isSocialMediaVisible, setIsSocialMediaVisible] = useState(false);

  // Map the socialMediaData to the correct type
  const mappedSocialMediaData = mapSocialMediaData(socialMediaData);

  return (
    <NavContainer>
      {/* Navigation and Action Links */}
      <LinkList $animate={isSocialMediaVisible}>
        {/* Navigation Links */}
        <NavLinks navLinkData={navLinkData} location={location} />
        {/* Action Links */}
        {/* <ActionLinks join={navLinkData.join} contact={navLinkData.contact} /> */}
        <li>
          {/* External Link to ASIFA-Hollywood */}
          <a
            href="https://www.asifa-hollywood.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLogo src={AsifaLogo.src} alt="ASIFA-Hollywood Logo" />
          </a>
        </li>
      </LinkList>

      {/* Social Media Links */}
      <SocialContainer $animate={!isSocialMediaVisible}>
        <SocialLinks socialMediaData={mappedSocialMediaData} />
      </SocialContainer>

      {/* Toggle Button for Social Media Links */}
      <ToggleContainer>
        <ToggleButton
          type="button"
          onClick={() => setIsSocialMediaVisible(!isSocialMediaVisible)}
          aria-label="Toggle social media links"
        >
          <FontAwesomeIcon icon={isSocialMediaVisible ? faTimes : faShareAlt} />
        </ToggleButton>
      </ToggleContainer>
    </NavContainer>
  );
};

export default DesktopNav;
