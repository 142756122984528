'use client';

// external libraries
import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuList, MenuButton } from '@reach/menu-button';

// Active styles for the button
export const activeStyle = css`
  background: var(--c-yellow-1);
`;

// Props type for StyledMenuButton
interface StyledMenuButtonProps {
  $menuIsActive: boolean;
}

// Styled component for MenuButton with active state handling
export const StyledMenuButton = styled(MenuButton)<StyledMenuButtonProps>`
  display: flex;
  align-items: center;
  background: ${({ $menuIsActive }) =>
    $menuIsActive ? `var(--c-yellow-1)` : `transparent`};
  color: var(--c-dark-grey-3);
  border: none;
  padding: var(--size-nudge) var(--size-half);
  border-radius: var(--radius-sm);
  cursor: pointer;
  transition: background 0.3s var(--animation-bezier);

  span {
    display: inherit;
  }

  :hover,
  :focus,
  &[data-reach-menu-button][aria-expanded='true'] {
    ${activeStyle}
  }

  &[data-reach-menu-button][aria-expanded='true'] .fa-chevron-down {
    transform: rotate(180deg);
  }
`;

// Styled component for FontAwesomeIcon
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: var(--size-dual-nudge);
  margin-left: var(--size-nudge);
  transform: rotate(0deg);
  transition: transform 0.3s var(--animation-bezier);
`;

// Keyframes for menu list animation
export const menuListAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateX(-12px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled component for MenuList with animation
export const StyledMenuList = styled(MenuList)`
  padding: var(--size-1);
  margin-top: var(--size-1h);
  border: none;
  border-top: 2px solid var(--c-yellow-1);
  border-radius: 0 0 var(--radius-sm) var (--radius-sm);
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.1);

  &[data-reach-menu-list] {
    animation: ${menuListAnimation} 0.6s var(--animation-bezier);
  }

  &[data-reach-menu-list],
  &[data-reach-menu-items] {
    display: flex;
    flex-direction: column;
    grid-gap: var(--size-1);
    transition: width 0.3s ease;
  }

  [data-reach-menu-item] {
    padding: 0;
    background: transparent;
    color: var(--c-grey-4);
    transition: 0.3s var(--animation-bezier);

    :hover,
    &[data-selected] {
      color: var(--c-yellow-2);
      transform: scale(1.03);
      font-weight: 600;
    }
  }
`;
