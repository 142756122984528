'use client';

// Third Party Plugins
import React, { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { ThemeProvider } from 'styled-components';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import 'normalize.css';
// Types
import { RootClientLayoutProps } from './types';
// Styles
import GlobalStyles from '@/assets/styles/GlobalStyles';
import Typography from '@/assets/styles/Typography';
// Custom Components
import Nav from '../Nav';
import Footer from '../Footer';
import LayoutContainer from '../LayoutContainer';
import ScrollToTopButton from '../ScrollToTopButton';

const RootClientLayout: React.FC<RootClientLayoutProps> = ({ children }) => {
  // State to manage the menu's open/closed state
  const [menuOpen, setMenuOpen] = useState(false);

  // Hook to get the current pathname, allowing us to react to navigation changes
  const pathname = usePathname();

  // Effect to close the menu when the pathname changes (navigation occurs)
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  // Inline styles for the menu when it is open
  const menuStyles: React.CSSProperties = {
    margin: 0,
    height: '100%',
    overflow: 'hidden',
  };

  return (
    // ThemeProvider to apply styled-components theming
    <ThemeProvider theme={GlobalStyles}>
      {/* Applying global styles */}
      <GlobalStyles />
      <Typography />

      {/* Root container with conditional styles based on menu state */}
      <div id="root" style={menuOpen ? menuStyles : undefined}>
        {/* Layout container with relative positioning */}
        <LayoutContainer style={{ position: 'relative', zIndex: 10 }}>
          <div>
            {/* Skip navigation link for accessibility */}
            <SkipNavLink />

            {/* Navigation component with props for menu state and current location */}
            <Nav
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              location={pathname}
            />

            {/* Skip navigation content for accessibility */}
            <SkipNavContent />
          </div>
        </LayoutContainer>

        {/* Main content area, hidden when the menu is open */}
        <main
          className="main-content"
          style={{
            display: menuOpen ? 'none' : 'block',
            position: 'relative',
            zIndex: 0,
          }}
        >
          {children}
        </main>

        {/* Footer component */}
        <Footer />
      </div>

      {/* Scroll-to-top button component */}
      <ScrollToTopButton />
    </ThemeProvider>
  );
};

export default RootClientLayout;
