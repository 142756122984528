// Third Party Plugin
import React from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
// Types
import { SitemapMapperProps } from './types';
// Styles
import { StyledSitemapMapper } from './styles';

const SitemapMapper: React.FC<SitemapMapperProps> = ({
  title,
  links = [],
  social = [],
}) => {
  return (
    <StyledSitemapMapper>
      <h3>{title}</h3>
      <ul>
        {links.map((link, index) => {
          // Check if link is defined and has the necessary properties
          if (!link || !link.url) {
            return null;
          }

          const { type, url, text } = link;
          return (
            <li key={index}>
              {type === 'nextjs' ? (
                <Link href={url} passHref legacyBehavior>
                  <a>{text}</a>
                </Link>
              ) : (
                <a href={url} target="_blank" rel="noreferrer">
                  {text}
                </a>
              )}
            </li>
          );
        })}
      </ul>
      {social.length > 0 && (
        <ul className="social-links">
          {social.map(({ url, icon }, index) => (
            <li key={index}>
              <a href={url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={icon as IconProp} />
              </a>
            </li>
          ))}
        </ul>
      )}
    </StyledSitemapMapper>
  );
};

export default SitemapMapper;
