'use client';

import styled from 'styled-components';
import Link from 'next/link';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  background: transparent;
  color: var(--c-dark-grey-3);
  border: none;
  padding: var(--size-nudge) var(--size-half);
  border-radius: var(--size-half);
  cursor: pointer;
  transition: background 0.3s var(--animation-bezier);

  &:hover,
  &.active {
    background: var(--c-yellow-1);
  }
`;
