// Third Party Plugins
import React from 'react';
// API - Local Data
import navLinkData from '../../data/navLinksData/navLinksData';
// Types
import { NavLinkDataProps } from '../../data/navLinksData/types';
import { NavLinksProps } from './types';
// Styles
import { StyledLink } from './styles';
// Custom Components
import TopNavDropdown from '../TopNavDropdown';

const navLinkKeys = Object.keys(navLinkData) as Array<keyof NavLinkDataProps>;

// This component maps over navLinkData keys and renders a TopNavDropdown for each key.
function NavLinks({ navLinkData, location }: NavLinksProps): JSX.Element {
  return (
    <>
      {/* Map over navLinkKeys and render a TopNavDropdown or a StyledLink based on the presence of sub-items */}
      {navLinkKeys.map((key) => (
        <li key={key}>
          {Array.isArray(navLinkData[key]) ? (
            <TopNavDropdown
              buttonText={key.charAt(0).toUpperCase() + key.slice(1)} // Capitalize the first letter of the key
              dropdownLinkData={navLinkData[key] as any[]} // Ensure it's an array
              location={location} // Pass the current location
            />
          ) : (
            <StyledLink href={(navLinkData[key] as any).url}>
              {(navLinkData[key] as any).text}
            </StyledLink>
          )}
        </li>
      ))}
    </>
  );
}

export default NavLinks;
