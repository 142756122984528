// Third Party Plugins
import React from 'react';
// Types
import { LinkMapperProps } from './types';
// Custom Components
import LinkComponent from '.';

const LinkMapper: React.FC<LinkMapperProps> = ({ data }) => {
  if (Array.isArray(data)) {
    // If data is an array, map over it
    return (
      <>
        {data.map((item, index) => (
          <LinkComponent key={index} item={item} />
        ))}
      </>
    );
  } else if (typeof data === 'object' && data !== null) {
    // If data is an object, render it directly
    return <LinkComponent item={data} />;
  } else {
    console.error('LinkMapper received invalid data:', data);
    return <div>Invalid data</div>;
  }
};

export default LinkMapper;
