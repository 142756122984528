'use client';

import styled, { css } from 'styled-components';

export const activeSocialStyle = css`
  color: var(--c-yellow-1);
  transform: scale(1.2);
`;

export const SocialLink = styled.a`
  @media screen and (prefers-reduced-motion: reduce) {
    > svg {
      transition: none;
      color: var(--c-grey-4);
    }
  }

  > svg {
    transition: all 0.3s var(--animation-bezier);
    color: var(--c-grey-4);
  }

  :hover svg,
  :focus svg {
    ${activeSocialStyle}
  }
`;
