// Types
import { VisuallyHiddenProps } from './types';

// Define the styles to visually hide the element but keep it accessible to screen readers
const styles = {
  position: 'absolute' as 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: '0',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: '0',
};

// Define the VisuallyHidden functional component
const VisuallyHidden: React.FC<VisuallyHiddenProps> = ({ children }) => (
  <span style={styles}>{children}</span>
);

export default VisuallyHidden;
