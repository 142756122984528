// Third Party Plugins
import React from 'react';
import Link from 'next/link';
import { Menu, MenuLink, MenuList } from '@reach/menu-button';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import '@reach/menu-button/styles.css';
// Types
import { TopNavDropdownProps } from './types';
// Styles
import {
  StyledFontAwesomeIcon,
  StyledMenuButton,
  StyledMenuList,
} from './styles';

// This component renders a dropdown menu in the top navigation bar with links.
function TopNavDropdown({
  buttonText,
  dropdownLinkData,
  location,
}: TopNavDropdownProps) {
  // Ensure dropdownLinkData is an array
  if (!Array.isArray(dropdownLinkData)) {
    return null; // or handle the error as appropriate for your application
  }

  // Determine if the current page matches any of the dropdown link URLs
  let onCurrentPage = false;
  if (location) {
    onCurrentPage = dropdownLinkData.some((page) => page.url === location);
  }

  return (
    <>
      {location && (
        <Menu>
          {/* Menu button with an active state based on the current page */}
          <StyledMenuButton $menuIsActive={onCurrentPage}>
            {buttonText}
            <span aria-hidden>
              <StyledFontAwesomeIcon icon={faChevronDown} />
            </span>
          </StyledMenuButton>

          {/* Menu list with links */}
          <StyledMenuList as={MenuList}>
            {dropdownLinkData.map((item, index) => {
              if (item.type === 'nextjs') {
                return (
                  // Render a Next.js link
                  <Link
                    style={{ textDecoration: 'none' }}
                    key={index + item.text}
                    href={item.url}
                    passHref
                  >
                    <MenuLink as="a" onSelect={() => {}}>
                      {item.text}
                    </MenuLink>
                  </Link>
                );
              }
              return (
                // Render an external link
                <MenuLink
                  key={index + item.text}
                  as="a"
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onSelect={() => {}} // Ensure the dropdown closes on select
                >
                  {item.text}
                </MenuLink>
              );
            })}
          </StyledMenuList>
        </Menu>
      )}
    </>
  );
}

export default TopNavDropdown;
