// Third Party Plugins
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Types
import { SocialLinksProps } from './types';
// Styles
import { SocialLink } from './styles';

function SocialLinks({ socialMediaData }: SocialLinksProps) {
  return (
    <>
      {socialMediaData.map((item, index) => {
        const { icon, url } = item;
        return icon && url ? (
          <SocialLink
            key={`${index}-${String(icon)}`}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={icon} />
          </SocialLink>
        ) : null;
      })}
    </>
  );
}

export default SocialLinks;
