// Third Party Plugins
import React from 'react';
// Types
import { LayoutProps } from './types';
// Styles
import { StyledLayoutContainer } from './styles';

function LayoutContainer({ hasSectionGaps, children, ...props }: LayoutProps) {
  return (
    <StyledLayoutContainer $gap={hasSectionGaps} {...props}>
      {children}
    </StyledLayoutContainer>
  );
}

export default LayoutContainer;
