'use client';

// external libraries
import styled, { css } from 'styled-components';
// styles
import { activeSocialStyle } from '../SocialLinks/styles';

export const activeStyle = css`
  background: var(--c-yellow-1);
`;

export const NavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: fit-content;
  margin-left: var(--size-1);
`;

export const SocialContainer = styled.div<{ $animate: boolean }>`
  position: absolute;
  display: flex;
  padding-right: var(--size-1);
  align-items: center;
  grid-gap: var(--size-1h);
  opacity: ${(props) => (props.$animate ? 0 : 1)};
  visibility: ${(props) => (props.$animate ? 'hidden' : 'visible')};
  right: ${(props) => (props.$animate ? 'var(--size-1)' : 'var(--size-1dn)')};
  transition: all 0.3s var(--animation-bezier);
`;

export const LinkList = styled.ul<{ $animate: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: var(--size-nudge);
  padding-inline-start: 0;
  padding-right: var(--size-half);
  transition: all 0.3s var(--animation-bezier);
  opacity: ${(props) => (props.$animate ? 0 : 1)};
  visibility: ${(props) => (props.$animate ? 'hidden' : 'visible')};
  transform: ${(props) =>
    props.$animate ? 'translateX(var(--size-1))' : 'translateX(0)'};
`;

export const ToggleContainer = styled.div`
  display: flex;
  grid-gap: var(--size-half);
  padding-left: var(--size-dual-nudge);
  border-left: solid 1px var(--c-grey-1);
`;

export const ToggleButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  width: var(--size-1);
  padding: 0;

  @media screen and (prefers-reduced-motion: reduce) {
    > svg {
      transition: none;
      color: var(--c-grey-4);
    }
  }

  > svg {
    transition: all 0.3s var(--animation-bezier);
    color: var(--c-grey-4);
  }

  :hover svg,
  :focus svg {
    ${activeSocialStyle}
  }
`;

export const StyledLogo = styled.img`
  width: 2.5em;
  height: 2.5em;
  margin: -1em 0;
`;
