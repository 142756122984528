// Third Party Plugins
import React from 'react';
import Link from 'next/link';
import {
  IconDefinition,
  findIconDefinition,
} from '@fortawesome/fontawesome-svg-core';
// API - Local Data
import navLinkData from './data/navLinksData/navLinksData';
import socialMediaLocalData from './data/socialMediaData';
// Types
import { NavProps } from './types';
import { SocialMediaData } from './data/navLinksData/types';
import { SocialMediaDataProps } from './data/socialMediaData/types';
// Custom Components
import DesktopNavContent from './_components/DesktopNav';
import MobileNav from './_components/MobileNav';
// Styles
import { StyledLogoWrapper, StyledNav } from './styles';
// Images
import AEFLogoLight from '@svg/aef-full-lightmode-logo-lg-top-tag.svg';

// Mapping function to ensure correct types
const mapSocialMediaData = (
  data: SocialMediaDataProps[],
): SocialMediaData[] => {
  return data.map((item, index) => ({
    platform: item.platform,
    url: item.url,
    icon:
      typeof item.icon === 'string'
        ? findIconDefinition({ prefix: 'fas', iconName: item.icon })!
        : (item.icon as IconDefinition),
    id: item.id || index.toString(),
  }));
};

// Convert the imported socialMediaLocalData to the correct type
const socialMediaData: SocialMediaData[] =
  mapSocialMediaData(socialMediaLocalData);

const Nav: React.FC<NavProps> = ({ menuOpen, setMenuOpen, location }) => {
  return (
    <StyledNav>
      <Link href="/">
        <StyledLogoWrapper>
          <AEFLogoLight />
        </StyledLogoWrapper>
      </Link>
      <div className="desktop-nav">
        <DesktopNavContent
          location={location}
          socialMediaData={socialMediaData} // Now correctly typed
          navLinkData={navLinkData}
        />
      </div>
      <div className="mobile-nav">
        <MobileNav
          socialMediaData={socialMediaData} // Now correctly typed
          navLinkData={navLinkData}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      </div>
    </StyledNav>
  );
};

export default Nav;
