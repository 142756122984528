import { IconProp, findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

// Utility function to convert IconProp to IconDefinition if needed
export const convertIconPropToIconDefinition = (
  icon: IconProp,
): IconDefinition | null => {
  if (typeof icon === 'string') {
    return findIconDefinition({ prefix: 'fas', iconName: icon });
  }
  return icon as IconDefinition;
};

// Mapping function to ensure socialMediaData is correctly typed

export const mapSocialMediaData = (socialMediaData: any[]): any[] => {
  return socialMediaData.map((item) => ({
    ...item,
    icon: convertIconPropToIconDefinition(item.icon),
  }));
};
