import { NavLinkDataProps } from './types';

const navLinkData: NavLinkDataProps = {
  about: [
    { type: 'nextjs', url: '/about', text: 'Who We Are' },
    { type: 'nextjs', url: '/history', text: 'AEF History' },
    { type: 'nextjs', url: '/legends', text: 'AEF Hall Of Fame' },
  ],
  engage: [
    { type: 'nextjs', url: '/forum', text: 'The Forum' },
    { type: 'nextjs', url: '/events', text: 'Events' },
    { type: 'nextjs', url: '/news', text: 'News' },
    {
      type: 'nextjs',
      url: '/events/birds-of-a-feather-discussion',
      text: 'Birds of a Feather',
    },
    {
      type: `nextjs`,
      url: `/events/animation-salon`,
      text: `Animation Salon`,
    },
    {
      type: `nextjs`,
      url: `/books`,
      text: `Animation Book Database`,
    },
    { type: 'nextjs', url: '/resources', text: 'Resources' },
  ],
  programs: [
    { type: 'nextjs', url: '/scholarships', text: 'Student Scholarship' },
    {
      type: 'nextjs',
      url: '/past-scholarship-winners',
      text: 'Past Scholarship Winners',
    },
    { type: 'nextjs', url: '/grants', text: 'Faculty Grants' },
    { type: 'nextjs', url: '/past-grant-winners', text: 'Past Grant Winners' },
    {
      type: 'nextjs',
      url: '/student-animation-showcase',
      text: 'Student Animation Showcase',
    },
  ],
  connect: [
    { type: 'nextjs', url: '/join', text: 'Join' },
    { type: 'nextjs', url: '/contact', text: 'Contact' },
  ],
};

export default navLinkData;
